export default [
  {
    name: 'Apps',
    iconUrl: '/icons/application.svg',
    selectedItem: null,
    linkPath: '/applications',
  },
  {
    name: 'Users',
    iconUrl: '/icons/users.svg',
    selectedItem: null,
    linkPath: '/users',
  },
  {
    name: 'User Search',
    iconUrl: '/icons/search-user.svg',
    selectedItem: null,
    linkPath: '/user-search',
    permissions: [
      'search_user',
    ],
  }
];
